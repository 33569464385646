import React from "react";

interface HeaderProps {

}
interface HeaderState {

}


export class Header extends React.Component<HeaderProps, HeaderState>
{
  constructor(props: HeaderProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ paddingTop: "0px" }}>
        <div style={{
          textAlign: "right",
          fontSize: "28px",
          fontFamily: "Lato",
          paddingTop: "24px",
          paddingRight: "12px",
          paddingBottom: "0px"

        }}>
          everything is game
        </div>
        <hr style={{ border: "1px solid #cccccc" }}></hr>
      </div>
    );
  }
}
