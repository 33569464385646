import React from "react";
import { APIService } from "../ApiService";
import * as Constants from "../Constants";
import { Button, Input, } from "@mui/material";
import { LoadingSpinner } from "../SharedComponents/LoadingSpinner";

interface CodesProps {
    apiService: APIService;
    authToken: string;
    activePlayer: string
    onBackClick: () => void;
}

interface CodesState {
    currentLifecycleState: CodesLifecycleState
    currentHintText: string;
    coinsAwarded: string;
    currentAnswer: string;
    allHintsExhausted: boolean;
    getNewHintButtonEnabled: boolean;
}

enum CodesLifecycleState {
    loadingHint = 0,
    showingInput = 1,
    checkingAnswer = 2,
    timedOutOnWrongAnswer = 3,
    showPointAwarded = 4,
    showingAlreadySolved = 5
}

export class Codes extends React.Component<CodesProps, CodesState>
{
    constructor(props: CodesProps) {
        super(props);
        this.state = {
            currentLifecycleState: CodesLifecycleState.loadingHint,
            currentHintText: "",
            coinsAwarded: "0",
            currentAnswer: "",
            allHintsExhausted: false,
            getNewHintButtonEnabled: false
        };

    }

    componentDidMount() {
        this.getHint();
    }

    getHint() {
        this.setState({
            getNewHintButtonEnabled: false,
            currentHintText: ""
        })
        this.props.apiService.getCodeHint(
            this.props.authToken,
            this.handleHintLoaded);
    }

    handleHintLoaded = (response: any) => {
        const payload = response?.data;
        window.console.log(payload)

        const hintText = payload?.[Constants.PayloadHelpers.WEB_GAME_GET_CODE_HINT_PAYLOAD];

        this.setState({
            currentLifecycleState: CodesLifecycleState.showingInput,
            currentHintText: (hintText && hintText.length > 0) ? hintText : "All hints exhausted",
            allHintsExhausted: !hintText
        });

        setTimeout(() => {
            window.console.log("turning hint button on")
            this.setState({
                getNewHintButtonEnabled: true
            })
        }, 60000);
    }

    submitAnswer = () => {
        this.setState({
            currentLifecycleState: CodesLifecycleState.checkingAnswer,
        })
        this.props.apiService.submitCodeAnswer(
            this.props.authToken,
            this.state.currentAnswer,
            this.props.activePlayer,
            this.handleAnswerResponse);
    }

    handleAnswerResponse = (response: any) => {

        const payload = response?.data;
        window.console.log(payload)

        // if answer is correct
        const result = payload[Constants.PayloadHelpers.WEB_GAME_CODE_RESULT];
        switch (result) {
            case Constants.PayloadHelpers.WEB_GAME_CODE_SUCCESS:
                this.setState({
                    currentAnswer: "",
                    coinsAwarded: payload[Constants.WebGameFieldNames.CODE_COINS_TO_AWARD],
                    currentLifecycleState: CodesLifecycleState.showPointAwarded
                });
                setTimeout(() => {
                    this.getHint();
                    this.setState({
                        coinsAwarded: "0",
                        currentLifecycleState: CodesLifecycleState.showingInput
                    });
                }, 5000);
                break;
            case Constants.PayloadHelpers.WEB_GAME_CODE_ALREADY_SOLVED:
                this.setState({
                    currentAnswer: "",
                    currentLifecycleState: CodesLifecycleState.showingAlreadySolved
                });
                setTimeout(() => {
                    this.setState({
                        currentLifecycleState: CodesLifecycleState.showingInput
                    });
                }, 3000);
                break;
            case Constants.PayloadHelpers.WEB_GAME_CODE_INCORRECT:
                this.setState({
                    currentAnswer: "",
                    currentLifecycleState: CodesLifecycleState.timedOutOnWrongAnswer
                });
                setTimeout(() => {
                    this.setState({
                        currentLifecycleState: CodesLifecycleState.showingInput
                    });
                }, 25000); // TODO = update
                break;
        }
    }

    render() {
        const refreshAvailable = this.state.getNewHintButtonEnabled && !this.state.allHintsExhausted;
        return (
            <>
                <div
                    style={{}}
                >
                    <h2>
                        Codes
                    </h2>
                    {this.state.currentLifecycleState === CodesLifecycleState.showingInput &&
                        <div style={
                            {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                            <Input style={{ width: "300px" }} onChange={(event: any) => { this.setState({ currentAnswer: event.target.value }) }} />
                            <br />
                            <Button onClick={this.submitAnswer}>Submit Answer</Button>

                            <br /><br />
                            <div
                                style={{
                                    width: "300px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                                onClick={() => {
                                    if (refreshAvailable) {
                                        this.getHint();
                                    }
                                    else {
                                        alert(this.state.allHintsExhausted ? "no more hints" : "please wait before getting a new hint")
                                    }
                                }}
                            >
                                {refreshAvailable
                                    ? <span className="material-icons">refresh</span>
                                    : <span className="material-icons">emoji_objects</span>}
                                {`RANDOM HINT: ${this.state.currentHintText}`}
                            </div>
                        </div>}
                    {this.state.currentLifecycleState === CodesLifecycleState.timedOutOnWrongAnswer && <div>
                        {`Oops! Not correct. Please try again in 25 seconds`}
                    </div>}
                    {this.state.currentLifecycleState === CodesLifecycleState.showPointAwarded && <div>
                        {`Congrats! ${this.state.coinsAwarded} Coins awarded.`}
                    </div>}
                    {this.state.currentLifecycleState === CodesLifecycleState.showingAlreadySolved && <div>
                        {`Someone already got that one! Try again`}
                    </div>}
                    {(this.state.currentLifecycleState === CodesLifecycleState.loadingHint
                        || this.state.currentLifecycleState === CodesLifecycleState.checkingAnswer)
                        && <LoadingSpinner />}
                </div>

                <br />
                <br />
                <Button onClick={this.props.onBackClick}>Go Back</Button>
            </>
        );
    }
}
