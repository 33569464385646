import React from "react";
import { Header } from "../Header";
import { APIService } from "../ApiService";
import * as Constants from "../Constants";
import { Trivia } from "./Trivia";
import TYLER_LOGO from "../Assets/tyler_logo_2.png";
import GAMES_LOGO from "../Assets/game_trivia.png";
import FOODANDBEER_LOGO from "../Assets/food_and_beer_trivia.png";
import { SubCard } from "./SubCard";


interface TriviaCategorySelectionProps {
    apiService: APIService;
    authToken: string;
    activePlayer: string;
    summaryData?: any;
    onBackClick: () => void;
}

interface TriviaCategorySelectionState {
    activeCatagory: AvailbleTriviaNavStates;
}


export enum AvailbleTriviaNavStates {
    Home = 0,
    Tyler = 1,
    Games = 2,
    FoodAndBeer = 3
}


export class TriviaCategorySelection extends React.Component<TriviaCategorySelectionProps, TriviaCategorySelectionState>
{
    constructor(props: TriviaCategorySelectionProps) {
        super(props);
        this.state = {
            activeCatagory: AvailbleTriviaNavStates.Home,
        };

    }

    handleNavClick = (navState: AvailbleTriviaNavStates) => {
        this.setState({ activeCatagory: navState });
    }

    render() {
        const triviaPayload = this.props.summaryData?.[Constants.PayloadHelpers.WEB_GAME_CATEGORY_TRIVIA];
        const tylerPayload = triviaPayload[Constants.PayloadHelpers.WEB_GAME_TRIVIA_TYLER_CATEGORY];
        const gamesPayload = triviaPayload[Constants.PayloadHelpers.WEB_GAME_TRIVIA_GAMES_CATEGORY];
        const foodAndBeerPayload = triviaPayload[Constants.PayloadHelpers.WEB_GAME_TRIVIA_FOOD_AND_BEER_CATEGORY];


        const totalTylerCoinsAvailable = tylerPayload[Constants.PayloadHelpers.WEB_GAME_TOTAL_COINS_AVAIALBLE];
        const tylerCoinsClaimed = tylerPayload[Constants.PayloadHelpers.WEB_GAME_COINS_CLAIMED];
        const totalGamesCoinsAvailable = gamesPayload[Constants.PayloadHelpers.WEB_GAME_TOTAL_COINS_AVAIALBLE];
        const gamesCoinsClaimed = gamesPayload[Constants.PayloadHelpers.WEB_GAME_COINS_CLAIMED];
        const totalfoodAndBeerCoinsAvailable = foodAndBeerPayload[Constants.PayloadHelpers.WEB_GAME_TOTAL_COINS_AVAIALBLE];
        const foodAndBeerCoinsClaimed = foodAndBeerPayload[Constants.PayloadHelpers.WEB_GAME_COINS_CLAIMED];

        const tylerEnabled = totalTylerCoinsAvailable !== tylerCoinsClaimed;
        const gamesEnabled = totalGamesCoinsAvailable !== gamesCoinsClaimed;
        const foodAndBeerEnabled = totalfoodAndBeerCoinsAvailable !== foodAndBeerCoinsClaimed;

        return (
            <>
                {this.state.activeCatagory === AvailbleTriviaNavStates.Home &&
                    <>
                        <h2 style={{
                        }}>
                            {`welcome ${this.props.activePlayer}`}
                        </h2>
                        <div style={{
                            display: "flex",
                            margin: "auto",
                            flexWrap: "wrap",
                            alignContent: "space-between",
                            justifyContent: "space-evenly",
                            alignItems: "center"
                        }}>
                            <SubCard
                                onClick={() => {
                                    if (tylerEnabled) {
                                        this.handleNavClick(AvailbleTriviaNavStates.Tyler)
                                    }
                                    else {
                                        alert("all tyler trivia complete");
                                    }
                                }}
                                header="Category: Tyler"
                                logo={TYLER_LOGO}
                                coinsClaimed={tylerCoinsClaimed}
                                coinsTotal={totalTylerCoinsAvailable}
                            />
                            <SubCard
                                onClick={() => {
                                    if (gamesEnabled) {
                                        this.handleNavClick(AvailbleTriviaNavStates.Games)
                                    }
                                    else {
                                        alert("all games trivia complete");
                                    }
                                }}
                                header="Category: Games"
                                logo={GAMES_LOGO}
                                coinsClaimed={gamesCoinsClaimed}
                                coinsTotal={totalGamesCoinsAvailable}
                            />
                            <SubCard
                                onClick={() => {
                                    if (foodAndBeerEnabled) {
                                        this.handleNavClick(AvailbleTriviaNavStates.FoodAndBeer)
                                    }
                                    else {
                                        alert("all advanture coins claimed");
                                    }
                                }}
                                header="Category: Food and Beer"
                                logo={FOODANDBEER_LOGO}
                                coinsClaimed={foodAndBeerCoinsClaimed}
                                coinsTotal={totalfoodAndBeerCoinsAvailable}
                            />
                        </div>
                    </>
                }
                {this.state.activeCatagory === AvailbleTriviaNavStates.Tyler &&
                    < Trivia
                        apiService={this.props.apiService}
                        activeCatagory={Constants.PayloadHelpers.WEB_GAME_TRIVIA_TYLER_CATEGORY}
                        authToken={this.props.authToken}
                        activePlayer={this.props.activePlayer}
                        onBackClick={this.props.onBackClick} />
                }

                {this.state.activeCatagory === AvailbleTriviaNavStates.Games &&
                    < Trivia
                        apiService={this.props.apiService}
                        activeCatagory={Constants.PayloadHelpers.WEB_GAME_TRIVIA_GAMES_CATEGORY}
                        authToken={this.props.authToken}
                        activePlayer={this.props.activePlayer}
                        onBackClick={this.props.onBackClick} />
                }

                {this.state.activeCatagory === AvailbleTriviaNavStates.FoodAndBeer &&
                    < Trivia
                        apiService={this.props.apiService}
                        activeCatagory={Constants.PayloadHelpers.WEB_GAME_TRIVIA_FOOD_AND_BEER_CATEGORY}
                        authToken={this.props.authToken}
                        activePlayer={this.props.activePlayer}
                        onBackClick={this.props.onBackClick} />
                }
            </>
        );
    }
}
