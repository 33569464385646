import React from "react";
import * as Constants from "./Constants";
import { Button, Card, FormLabel, Icon, Menu, TextField } from "@mui/material";
interface ActivityListItemProps {
    activity: any;
    onDeleteCallback: (activityName: string) => void;
    onResetCallback: (activityName: string) => void;
    onCompleteCallback: (activityName: string, pointsGranted: any, coinsGranted: any) => void;
}
interface ActivityListItemState {
    showingDetails: boolean,
    completingActivity: boolean,
    anchorEl: any,
    brianScore: number,
    brianCoins: number,
    kenScore: number,
    kenCoins: number,
    mattScore: number,
    mattCoins: number,
    preetamScore: number,
    preetamCoins: number,
    tylerScore: number,
    tylerCoins: number,
}


export class ActivityListItem extends React.Component<ActivityListItemProps, ActivityListItemState>
{
    constructor(props: ActivityListItemProps) {
        super(props);
        this.state = {
            showingDetails: false,
            completingActivity: false,
            anchorEl: undefined,
            brianScore: 0,
            brianCoins: 0,
            kenScore: 0,
            kenCoins: 0,
            mattScore: 0,
            mattCoins: 0,
            preetamScore: 0,
            preetamCoins: 0,
            tylerScore: 0,
            tylerCoins: 0
        };
    }

    onActivitySubmitted = () => {

        let pointsGranted: any = {}
        pointsGranted[Constants.UserIDs.BRIAN] = this.state.brianScore.toString()
        pointsGranted[Constants.UserIDs.KEN] = this.state.kenScore.toString()
        pointsGranted[Constants.UserIDs.MATT] = this.state.mattScore.toString()
        pointsGranted[Constants.UserIDs.PREETAM] = this.state.preetamScore.toString()
        pointsGranted[Constants.UserIDs.TYLER] = this.state.tylerScore.toString()

        let coinsGranted: any = {}
        coinsGranted[Constants.UserIDs.BRIAN] = this.state.brianCoins.toString()
        coinsGranted[Constants.UserIDs.KEN] = this.state.kenCoins.toString()
        coinsGranted[Constants.UserIDs.MATT] = this.state.mattCoins.toString()
        coinsGranted[Constants.UserIDs.PREETAM] = this.state.preetamCoins.toString()
        coinsGranted[Constants.UserIDs.TYLER] = this.state.tylerCoins.toString()

        this.props.onCompleteCallback(
            this.props.activity[Constants.ActivityRepositoryFieldNames.ACTIVITY_NAME],
            pointsGranted,
            coinsGranted
        );

        this.setState({ completingActivity: false });

    }

    render() {
        const isComplete = this.props.activity[Constants.ActivityRepositoryFieldNames.COMPLETED];

        type coinPointAssignment = {
            ken: string,
            brian: string,
            matt: string,
            preetam: string,
            tyler: string
        }

        const pointassignment: coinPointAssignment = this.props.activity[Constants.ActivityRepositoryFieldNames.POINTSGRANTED];
        const coinassignment: coinPointAssignment = this.props.activity[Constants.ActivityRepositoryFieldNames.COINSGRANTED];

        return (
            <Card
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    maxWidth: "400px",
                    margin: "auto",
                    marginBottom: "4px",
                    backgroundColor: isComplete ? "lightgrey" : "white"
                }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-evenly",

                }} onClick={() => { this.setState((prevState) => { return { showingDetails: !prevState.showingDetails }; }) }}>
                    <div>{this.props.activity[Constants.ActivityRepositoryFieldNames.ACTIVITY_NAME]} </div>
                    <div>{this.props.activity[Constants.ActivityRepositoryFieldNames.DISPLAY_NAME]}</div>
                    <Icon >{this.state.showingDetails ? "arrow_drop_down" : "arrow_right"}</Icon>

                </div>
                {this.state.showingDetails &&
                    <>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                            {!isComplete && <Button style={{
                                paddingBottom: "18px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }} onClick={(event) => { this.setState({ anchorEl: event.currentTarget, completingActivity: true }) }}>
                                <div>Complete Activity </div>
                                <Icon>star</Icon>
                            </Button>}
                            <div style={{ textAlign: "left", paddingLeft: "8px" }}>
                                {this.props.activity[Constants.ActivityRepositoryFieldNames.LOCATION]}
                            </div>
                            <div style={{ textAlign: "left", paddingLeft: "8px" }}>
                                {this.props.activity[Constants.ActivityRepositoryFieldNames.AWARDS_NOTES]}
                            </div>

                        </div>
                        {
                            isComplete && <>
                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <div style={{ textAlign: "left", paddingLeft: "8px" }}>
                                        Points
                                        <ul style={{ marginTop: "0px" }}>
                                            <li>Brian {pointassignment.brian}</li>
                                            <li>Ken {pointassignment.ken}</li>
                                            <li>Matt {pointassignment.matt}</li>
                                            <li>Preetam {pointassignment.preetam}</li>
                                            <li>Tyler {pointassignment.tyler}</li>
                                        </ul>
                                    </div>

                                    <div style={{ textAlign: "left", paddingLeft: "8px" }}>
                                        Coins
                                        <ul style={{ marginTop: "0px" }}>
                                            <li>Brian {coinassignment.brian}</li>
                                            <li>Ken {coinassignment.ken}</li>
                                            <li>Matt {coinassignment.matt}</li>
                                            <li>Preetam {coinassignment.preetam}</li>
                                            <li>Tyler {coinassignment.tyler}</li>
                                        </ul>
                                    </div>
                                </div>
                                <Button style={{
                                    paddingBottom: "18px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                    onClick={() => { this.props.onResetCallback(this.props.activity[Constants.ActivityRepositoryFieldNames.ACTIVITY_NAME]) }}>
                                    <div>Clear Activity </div>
                                    <Icon>undo</Icon>
                                </Button>
                            </>
                        }
                        <Button style={{
                            paddingBottom: "18px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                            onClick={() => { this.props.onDeleteCallback(this.props.activity[Constants.ActivityRepositoryFieldNames.ACTIVITY_NAME]) }}>
                            <div>Remove Activity </div>
                            <Icon>delete</Icon>
                        </Button>
                    </>
                }
                {this.state.completingActivity &&
                    <Menu
                        open={this.state.completingActivity}
                        anchorEl={this.state.anchorEl}
                        PaperProps={{
                            style: {
                                width: "100%",
                                margin: "auto",
                                height: "90%"
                            }
                        }}
                    >
                        <h1 style={{ textAlign: "center" }}>{`Completing ${this.props.activity[Constants.ActivityRepositoryFieldNames.ACTIVITY_NAME]}`}</h1>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginBottom: "20px"
                        }}>
                            <FormLabel style={{ width: "20%" }}>Brian</FormLabel>
                            <TextField
                                value={this.state.brianScore}
                                label={"Score"}
                                onChange={(event) => { this.setState({ brianScore: Number.parseInt(event.target.value) }) }}
                            />
                            <TextField
                                value={this.state.brianCoins}
                                label={"Coins"}
                                onChange={(event) => { this.setState({ brianCoins: Number.parseInt(event.target.value) }) }}
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginBottom: "20px"
                        }}>
                            <FormLabel style={{ width: "20%" }}>Ken</FormLabel>
                            <TextField
                                value={this.state.kenScore}
                                label={"Score"}
                                onChange={(event) => { this.setState({ kenScore: Number.parseInt(event.target.value) }) }}
                            />
                            <TextField
                                value={this.state.kenCoins}
                                label={"Coins"}
                                onChange={(event) => { this.setState({ kenCoins: Number.parseInt(event.target.value) }) }}
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginBottom: "20px"
                        }}>
                            <FormLabel style={{ width: "20%" }}>Matt</FormLabel>
                            <TextField
                                value={this.state.mattScore}
                                label={"Score"}
                                onChange={(event) => { this.setState({ mattScore: Number.parseInt(event.target.value) }) }}
                            />
                            <TextField
                                value={this.state.mattCoins}
                                label={"Coins"}
                                onChange={(event) => { this.setState({ mattCoins: Number.parseInt(event.target.value) }) }}
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginBottom: "20px"
                        }}>
                            <FormLabel style={{ width: "20%" }}>Preetam</FormLabel>
                            <TextField
                                value={this.state.preetamScore}
                                label={"Score"}
                                onChange={(event) => { this.setState({ preetamScore: Number.parseInt(event.target.value) }) }}
                            />
                            <TextField
                                value={this.state.preetamCoins}
                                label={"Coins"}
                                onChange={(event) => { this.setState({ preetamCoins: Number.parseInt(event.target.value) }) }}
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginBottom: "20px"
                        }}>
                            <FormLabel style={{ width: "20%" }}>Tyler</FormLabel>
                            <TextField
                                value={this.state.tylerScore}
                                label={"Score"}
                                onChange={(event) => { this.setState({ tylerScore: Number.parseInt(event.target.value) }) }}
                            />
                            <TextField
                                value={this.state.tylerCoins}
                                label={"Coins"}
                                onChange={(event) => { this.setState({ tylerCoins: Number.parseInt(event.target.value) }) }}
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly"
                        }}>
                            <Button style={{
                                paddingBottom: "18px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }} onClick={() => { this.setState({ completingActivity: false }) }}>
                                <div>Cancel</div>
                                <Icon>cancel</Icon>
                            </Button>
                            <Button style={{
                                paddingBottom: "18px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }} onClick={this.onActivitySubmitted}>
                                <div>Save And Close</div>
                                <Icon>save</Icon>
                            </Button>
                        </div>
                    </Menu>
                }
            </Card>
        );
    }
}
