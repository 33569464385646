import React from "react";
import { Leaderboard } from "./Leaderboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { APIService } from "./ApiService";
import { Admin } from "./Admin";
import { WebGame } from "./webgame/WebGame";

interface MainProps {

}
interface MainState {
  apiService: APIService;
}


export class Main extends React.Component<MainProps, MainState>
{
  constructor(props: MainProps) {
    super(props);
    this.state = {
      apiService: new APIService()
    };
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Leaderboard apiService={this.state.apiService} showLiveData={true} />} />
            <Route path="/lbtest" element={<Leaderboard apiService={this.state.apiService} showLiveData={true} />} />
            <Route path="/admin" element={<Admin apiService={this.state.apiService} />} />
            <Route path="/webgame" element={<WebGame apiService={this.state.apiService} />} />
          </Routes>
        </BrowserRouter>
      </>
    );
  }
}
