import { Button, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import * as Constants from "./Constants";

interface AddNewPurchaseFormProps {
  onPurchaseSubmitted: (userId: string, coinsSpent: number, notes: string, specialCard: string) => void;
}

interface AddNewPurchaseFormState {
  userID: string,
  coinsSpent: number,
  Notes: string,
  SpecialCard: string
}


export class AddNewPurchaseForm extends React.Component<AddNewPurchaseFormProps, AddNewPurchaseFormState>
{
  constructor(props: AddNewPurchaseFormProps) {
    super(props);
    this.state = {
      userID: "",
      coinsSpent: 0,
      Notes: "",
      SpecialCard: ""
    };
  }
  onPurchaseSubmitted = () => {
    this.props.onPurchaseSubmitted(
      this.state.userID,
      this.state.coinsSpent,
      this.state.Notes,
      this.state.SpecialCard);

    this.setState({
      userID: "",
      coinsSpent: 0,
      Notes: "",
      SpecialCard: ""
    });
  }

  render() {

    const allowSubmission: boolean = !!this.state.Notes
      && !!this.state.userID
      && this.state.coinsSpent !== 0;

    return (
      <>
        <h3> Add new Purchase</h3>
        <div style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center"
        }}>
          <Select
            label="UserName"
            onChange={(event) => { this.setState({ userID: event.target.value as string }) }}
            value={this.state.userID}
            sx={{ width: "120px", margin: "10px" }}
          >
            <MenuItem value={Constants.UserIDs.BRIAN}>{Constants.UserIDs.BRIAN}</MenuItem>
            <MenuItem value={Constants.UserIDs.MATT}>{Constants.UserIDs.MATT}</MenuItem>
            <MenuItem value={Constants.UserIDs.KEN}>{Constants.UserIDs.KEN}</MenuItem>
            <MenuItem value={Constants.UserIDs.PREETAM}>{Constants.UserIDs.PREETAM}</MenuItem>
            <MenuItem value={Constants.UserIDs.TYLER}>{Constants.UserIDs.TYLER}</MenuItem>
          </Select>
          <TextField
            onChange={(event) => { this.setState({ coinsSpent: Number.parseInt(event.target.value) }) }}
            type="number"
            label="Coins Spent"
            style={{ margin: "10px" }}
            value={this.state.coinsSpent}
          />
          <TextField
            onChange={(event) => { this.setState({ Notes: event.target.value }) }}
            label="Notes"
            style={{ margin: "10px" }}
            value={this.state.Notes}
          />
          <Select
            label="SpecialCard"
            onChange={(event) => { this.setState({ SpecialCard: event.target.value as string }) }}
            value={this.state.SpecialCard}
            sx={{ width: "120px", margin: "10px" }}
          >
            <MenuItem value={""}>None</MenuItem>
            <MenuItem value={Constants.AdventureConstants.KEY_ITEM}>KeyBlade</MenuItem>
            <MenuItem value={Constants.AdventureConstants.PELT_ITEM}>Pelt</MenuItem>
            <MenuItem value={Constants.AdventureConstants.SHOES_ITEM}>Shoes</MenuItem>
          </Select>
          <Button
            onClick={this.onPurchaseSubmitted}
            disabled={!allowSubmission}
            style={{ margin: "10px" }}
          >
            Submit
          </Button>
        </div>
      </>
    );
  }
}
