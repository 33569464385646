import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { PlayerData } from "./Leaderboard";

interface ScoreCardProps {
  playerData: PlayerData,
}

interface ScoreCardState {
  expanded: boolean;
}

export class ScoreCard extends React.Component<ScoreCardProps, ScoreCardState>
{
  constructor(props: ScoreCardProps) {
    super(props);
    this.state = { expanded: false };
  }


  render() {
    return (
      <Card
        sx={{
          width: 300,
          borderColor: this.props.playerData.borderColor,
          margin: "10px"
        }}
        onClick={() => { this.setState((prevState) => { return { expanded: !prevState.expanded } }); }}
      >
        <div
          style={{
            width: "300px",
            height: "300px",
            display: "flex",
            alignContent: "flex-end",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: this.props.playerData.imagebgColor
          }}
        >
          <CardMedia
            component="img"
            width="100%"
            image={this.props.playerData.imgSource}
            alt={this.props.playerData.displayName}

          />
        </div>
        <CardContent sx={{
          textAlign: "left",
          borderTop: "5px",
          height: this.state.expanded ? "inherit" : "86px"
        }}>
          <Typography gutterBottom variant="h5" component="div" sx={{ color: this.props.playerData.borderColor }}>
            {this.props.playerData.displayName}
          </Typography>

          <Typography variant="body2" fontWeight={400} fontSize={16} sx={{ color: "#444444" }}>
            {`Victory Points: ${this.props.playerData.currentScore ?? 0}`}
          </Typography>
          <Typography variant="body2" fontWeight={400} fontSize={16} sx={{ color: "#444444" }}>
            {`Coins: ${this.props.playerData.currentCurrencyBalance ?? 0}`}
          </Typography>
          {(this.props.playerData.ledger && this.props.playerData.ledger.length > 0) &&
            <>
              <div
                style={{ display: "flex" }}

              >
                <Typography variant="body2" fontSize={16} sx={{ color: "#747474" }}>
                  {"Ledger"}
                </Typography>
                <span className="material-icons" style={{}}>{this.state.expanded ? "expand_more" : "chevron_right"}</span>

              </div>
              {this.state.expanded &&
                <ul style={{ paddingLeft: "24px" }}>
                  {this.props.playerData.ledger.map((ledgerEntry: string) => { return <li key={ledgerEntry}>{ledgerEntry}</li> })}
                </ul>
              }
            </>
          }

        </CardContent>
      </Card>
    );
  }
}