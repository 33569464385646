import React from "react";
import { APIService } from "../ApiService";
import * as Constants from "../Constants";
import { Button, Input, } from "@mui/material";
import { LoadingSpinner } from "../SharedComponents/LoadingSpinner";

interface TriviaProps {
    apiService: APIService;
    authToken: string;
    activePlayer: string
    activeCatagory: string;
    onBackClick: () => void;
}

interface TriviaState {
    questionNumber: number
    currentLifecycleState: TriviaLifecycleState
    currentQuestionText: string;
    currentQuestionIsClaimed: boolean;
    currentQuestionCoinCount: number;
    currentAnswer: string;
}

enum TriviaLifecycleState {
    loadingQuestion = 0,
    showingQuestion = 1,
    checkingAnswer = 2,
    timedOutOnWrongAnswer = 3,
    showPointAwarded = 4,
    allQuestionsAnswered = 5
}

export class Trivia extends React.Component<TriviaProps, TriviaState>
{
    constructor(props: TriviaProps) {
        super(props);
        this.state = {
            questionNumber: 1,
            currentLifecycleState: TriviaLifecycleState.loadingQuestion,
            currentQuestionText: "",
            currentQuestionIsClaimed: false,
            currentQuestionCoinCount: 0,
            currentAnswer: ""

        };
    }

    componentDidMount() {
        this.getNextQuestion(this.state.questionNumber);
    }


    getNextQuestion(questionNumber: number) {
        this.props.apiService.getTriviaQuestion(
            this.props.authToken,
            this.props.activeCatagory,
            questionNumber.toString(),
            this.handleQuestionLoaded);
    }

    handleQuestionLoaded = (response: any) => {
        const payload = response?.data;
        window.console.log(payload);
        if (!payload?.[Constants.WebGameFieldNames.TRIVIA_QUESTION_QUESTION_TEXT] && this.state.questionNumber > 1) {
            window.console.log("all questions answered");
            this.setState({
                currentLifecycleState: TriviaLifecycleState.allQuestionsAnswered,
            });
        }
        else {
            this.setState((prevState) => {
                return {
                    currentQuestionText: payload[Constants.WebGameFieldNames.TRIVIA_QUESTION_QUESTION_TEXT],
                    currentQuestionCoinCount: payload[Constants.WebGameFieldNames.TRIVIA_QUESTION_COINS_TO_AWARD],
                    currentQuestionIsClaimed: payload[Constants.WebGameFieldNames.TRIVIA_QUESTION_COINS_CLAIMED],
                    currentLifecycleState: TriviaLifecycleState.showingQuestion,
                };
            });
        }
    }

    submitAnswer = () => {
        this.setState({
            currentLifecycleState: TriviaLifecycleState.checkingAnswer,
        })
        this.props.apiService.submitTriviaAnswer(
            this.props.authToken,
            this.props.activeCatagory,
            this.state.questionNumber.toString(),
            this.state.currentAnswer,
            this.props.activePlayer,
            this.handleAnswerResponse);
    }

    handleAnswerResponse = (response: any) => {

        const payload = response?.data;
        window.console.log(payload)

        // if answer is correct
        if (payload[Constants.PayloadHelpers.TRIVIA_QUESTION_ANSWER_WAS_CORRECT]) {
            const nextQuestion = this.state.questionNumber + 1;
            if (this.state.currentQuestionIsClaimed) {
                this.setState({
                    currentLifecycleState: TriviaLifecycleState.loadingQuestion,
                    currentAnswer: "",
                    questionNumber: nextQuestion,
                })
                this.getNextQuestion(nextQuestion)
            }
            else {
                // show congrats first
                this.setState({
                    currentLifecycleState: TriviaLifecycleState.showPointAwarded,
                    currentAnswer: "",
                    questionNumber: nextQuestion,
                })
                setTimeout(() => {
                    this.getNextQuestion(nextQuestion)
                }, 1000);
            }
        }
        else {
            this.setState({
                currentLifecycleState: TriviaLifecycleState.timedOutOnWrongAnswer,
                currentAnswer: ""
            })
            setTimeout(() => {
                this.setState({
                    currentLifecycleState: TriviaLifecycleState.showingQuestion,
                })
            }, 25000);
        }

    }

    render() {
        return (
            <>
                <div
                    style={{}}
                >
                    <h2>
                        {`Trivia ${this.props.activeCatagory}`}
                    </h2>
                    {this.state.currentLifecycleState === TriviaLifecycleState.showingQuestion &&
                        <div style={
                            {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                            <div style={{ width: "300px" }}>
                                {this.state.currentQuestionText}
                            </div>
                            <Input style={{ width: "300px" }} onChange={(event: any) => { this.setState({ currentAnswer: event.target.value }) }} />
                            <br />
                            <Button onClick={this.submitAnswer}>Submit Answer</Button>
                        </div>}
                    {this.state.currentLifecycleState === TriviaLifecycleState.timedOutOnWrongAnswer && <div>
                        {`Oops! Not correct. Please try again in 25 seconds`}
                    </div>}
                    {this.state.currentLifecycleState === TriviaLifecycleState.showPointAwarded && <div>
                        {`Congrats! ${this.state.currentQuestionCoinCount} Coins awarded. Loading next question...`}
                    </div>}
                    {this.state.currentLifecycleState === TriviaLifecycleState.allQuestionsAnswered && <div>
                        {`Congrats! All questions are answered.`}
                    </div>}
                    {(this.state.currentLifecycleState === TriviaLifecycleState.loadingQuestion
                        || this.state.currentLifecycleState === TriviaLifecycleState.checkingAnswer)
                        && <LoadingSpinner />}
                </div>

                <br />
                <br />
                <Button onClick={this.props.onBackClick}>Go Back</Button>
                {this.state.currentLifecycleState === TriviaLifecycleState.allQuestionsAnswered &&
                    <div style={{ fontSize: "9px", marginTop: "500px" }}>
                        {`eyespy alltriviaansweredforthiscategory`}
                    </div>}
            </>
        );
    }
}
