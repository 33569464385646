
import React from "react";
import GoogleLogin from "react-google-login";
import { ActivityListItem } from "./ActivityListItem";
import { AddNewActivityForm } from "./AddNewActivityForm";
import { AddNewAdjustmentForm } from "./AddNewAdjustmentForm";
import { AddNewPurchaseForm } from "./AddNewPurchaseForm";
import { AdjustmentListItem } from "./AdjustmentListItem";
import { APIService } from "./ApiService";
import * as Constants from "./Constants";
import { PurchaseListItem } from "./PurchaseListItem";

interface AdminProps {
  apiService: APIService;
}

interface AdminState {
  lbData: any,
  reloading: boolean;
  authToken: string
}

export class Admin extends React.Component<AdminProps, AdminState>
{
  constructor(props: AdminProps) {
    super(props);
    this.state = {
      lbData: null,
      authToken: "",
      reloading: false
    };
  }

  componentDidMount() {
    this.props.apiService.getAllData(this.onDataReceived);
  }

  onDataReceived = (response: any) => {
    console.log(response["data"]);
    const data = response["data"];
    console.log(data);

    this.setState({ lbData: data, reloading: false });
  }

  handleResponse = (response: any) => {
    this.setState({ reloading: true });
    this.props.apiService.getAllData(this.onDataReceived);
    console.log(response);
  }

  handleAddNewActivitySubmission = (activityName: string, activityDisplayName: string, activityLocation: string, activityAwardsNotes: string) => {

    this.props.apiService.addActivity(
      activityName,
      activityDisplayName,
      activityLocation,
      activityAwardsNotes,
      this.state.authToken,
      this.handleResponse
    );
  }

  handleDeleteActivity = (activityName: string) => {
    if (window.confirm(`Delete ${activityName} ?`)) {
      this.props.apiService.deleteActivity(activityName, this.state.authToken, this.handleResponse);
    }
  }

  handleResetActivity = (activityName: string) => {
    if (window.confirm(`Reset Activity ${activityName}?`)) {
      this.props.apiService.clearActivity(activityName, this.state.authToken, this.handleResponse)
    }
  }

  handleCompleteActivity = (activityName: string, pointsAwarded: any, coinsAwarded: any) => {
    this.props.apiService.completeActivity(activityName, pointsAwarded, coinsAwarded, this.state.authToken, this.handleResponse);
  }

  handleAddAdjustmentSubmission = (userId: string, pointsAdjusted: number, coinsAdjusted: number, notes: string) => {

    this.props.apiService.addAdjustment(
      userId,
      pointsAdjusted,
      coinsAdjusted,
      notes,
      this.state.authToken,
      this.handleResponse
    );
  }

  handleDeleteAdjustment = (userId: string, timeStamp: string) => {
    if (window.confirm(`Delete adjustment ${userId} + ${timeStamp}?`)) {
      this.props.apiService.deleteAdjustment(userId, timeStamp, this.state.authToken, this.handleResponse)
    }
  }

  handleAddPurchaseSubmission = (userId: string, coinsSpent: number, notes: string, specialCard: string) => {
    this.props.apiService.addPurchase(
      userId,
      coinsSpent,
      notes,
      specialCard,
      this.state.authToken,
      this.handleResponse
    );
  }

  handleDeletePurchase = (userId: string, timeStamp: string) => {
    if (window.confirm(`Delete purchase ${userId} + ${timeStamp}?`)) {
      this.props.apiService.deletePurchase(userId, timeStamp, this.state.authToken, this.handleResponse)
    }
  }

  handleLogin = (res: any) => {
    if (res.profileObj.email === "tlibey1@gmail.com") {
      this.setState({ authToken: res.tokenId });
    }
    console.log(res); console.log(res.profileObj);
  }

  render() {

    const isAuthed = !!this.state.authToken;

    return (
      <>
        <h1>Admin Page</h1>
        {isAuthed
          ? <>
            {this.state.reloading
              ? <>{"Loading"}</>
              : <>
                <h2>Activities</h2>
                {this.state.lbData && this.state.lbData[Constants.PayloadHelpers.ACTIVITY_LIST].map((activity: any) => {
                  return <ActivityListItem
                    key={activity[Constants.ActivityRepositoryFieldNames.ACTIVITY_NAME]}
                    activity={activity}
                    onDeleteCallback={this.handleDeleteActivity}
                    onResetCallback={this.handleResetActivity}
                    onCompleteCallback={this.handleCompleteActivity}
                  />
                })}
                <AddNewActivityForm onActivitySubmitted={this.handleAddNewActivitySubmission} />
                <hr />
                <h2>Purchases</h2>
                {this.state.lbData && this.state.lbData[Constants.PayloadHelpers.PURCHASE_LIST].map((purchase: any) => {
                  return <PurchaseListItem
                    key={purchase[Constants.PurchaseRepositoryFieldNames.USERID] + purchase[Constants.PurchaseRepositoryFieldNames.TIMESTAMP]}
                    purchase={purchase}
                    onDeleteCallback={this.handleDeletePurchase}
                  />
                })}
                <AddNewPurchaseForm onPurchaseSubmitted={this.handleAddPurchaseSubmission} />
                <hr />
                <h2>Modifications</h2>
                {this.state.lbData && this.state.lbData[Constants.PayloadHelpers.ADJUSTMENT_LIST].map((adjustment: any) => {
                  return <AdjustmentListItem
                    key={adjustment[Constants.PurchaseRepositoryFieldNames.USERID] + adjustment[Constants.PurchaseRepositoryFieldNames.TIMESTAMP]}
                    adjustment={adjustment}
                    onDeleteCallback={this.handleDeleteAdjustment}
                  />
                })}
                <AddNewAdjustmentForm onAdjustmentSubmitted={this.handleAddAdjustmentSubmission} />
              </>
            }
          </>
          : <>
            <GoogleLogin
              clientId="78490331453-rllhrrno34jfmmr0j0m0r66edj33dsim.apps.googleusercontent.com"
              buttonText="Admin Login"
              cookiePolicy="single_host_origin"
              onSuccess={this.handleLogin}
              prompt="consent"
            />
          </>
        }
      </>
    );
  }
}
