import React from "react";
import * as Constants from "./Constants";
import { Button, Card, Icon } from "@mui/material";

interface PurchaseListItemProps {
    purchase: any;
    onDeleteCallback: (userID: string, timeStamp: string) => void;
}

interface PurchaseListItemState {
    showingDetails: boolean
}

export class PurchaseListItem extends React.Component<PurchaseListItemProps, PurchaseListItemState>
{
    constructor(props: PurchaseListItemProps) {
        super(props);
        this.state = { showingDetails: false };
    }

    render() {
        return (
            <Card style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: "400px",
                margin: "auto",
                marginBottom: "4px"
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-evenly"
                }} onClick={() => { this.setState((prevState) => { return { showingDetails: !prevState.showingDetails }; }) }}>
                    <div>{this.props.purchase[Constants.PurchaseRepositoryFieldNames.USERID]} </div>
                    <div>{this.props.purchase[Constants.PurchaseRepositoryFieldNames.COINS_SPENT]}</div>
                    <Icon >{this.state.showingDetails ? "arrow_drop_down" : "arrow_right"}</Icon>

                </div>
                {this.state.showingDetails &&
                    <>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                            <div>{this.props.purchase[Constants.PurchaseRepositoryFieldNames.TIMESTAMP]}</div>
                            <div>{this.props.purchase[Constants.PurchaseRepositoryFieldNames.PURCHASE_NOTES]}</div>
                        </div>
                        <Button style={{
                            paddingBottom: "18px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                            onClick={() => {
                                this.props.onDeleteCallback(
                                    this.props.purchase[Constants.PurchaseRepositoryFieldNames.USERID],
                                    this.props.purchase[Constants.PurchaseRepositoryFieldNames.TIMESTAMP]
                                )
                            }}>
                            <div>Remove Purchase </div>
                            <Icon>delete</Icon>
                        </Button>
                    </>
                }
            </Card>
        );
    }
}
