import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";


interface SubCardProps {
    onClick: () => void,
    logo: string,
    coinsClaimed: string,
    coinsTotal: string,
    header: string
}

export class SubCard extends React.Component<SubCardProps>
{
    //constructor(props: SubCardProps) {
    // super(props);
    // }

    render() {

        return (
            <div style={{
                display: "flex",
                margin: "auto",
                flexWrap: "wrap",
                alignContent: "space-between",
                justifyContent: "space-evenly",
                alignItems: "center"
            }}>
                <Card
                    sx={{
                        width: 300,
                        borderColor: "#444444",
                        margin: "10px"
                    }}
                    onClick={this.props.onClick} //
                >
                    <CardMedia
                        component="img"
                        width="100%"
                        image={this.props.logo}

                    />
                    <CardContent sx={{
                        textAlign: "center",
                        borderTop: "5px"
                    }}>
                        <Typography gutterBottom variant="h4" component="div" sx={{ color: "#444444" }}>
                            {this.props.header}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                            {`Claimed Coins: ${this.props.coinsClaimed}/${this.props.coinsTotal}`}
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
