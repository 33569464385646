import React from "react";
import { Header } from "../Header";
import { APIService } from "../ApiService";
import * as Constants from "../Constants";
import GoogleLogin from "react-google-login";
import { Trivia } from "./Trivia";
import TRIVIA_LOGO from "../Assets/trivialogo.png";
import PUZZLE_LOGO from "../Assets/puzzlelogo.png";
import ADVENTURE_LOGO from "../Assets/adventurelogo.png";
import { SubCard } from "./SubCard";
import { Adventure } from "./Adventure";
import { Codes } from "./Codes";
import { LoadingSpinner } from "../SharedComponents/LoadingSpinner";
import { TriviaCategorySelection } from "./TriviaCategorySelection";


interface WebGameProps {
    apiService: APIService;
}

interface WebGameState {
    authToken: string;
    activePlayer: string;
    activeNavState: AvailbleNavStates;
    activeLifeCycle: WebGameLifeCycle;
    summaryData?: any;
}


enum AvailbleNavStates {
    Home = 0,
    Trivia = 1,
    Codes = 2,
    Adventure = 3
}

enum WebGameLifeCycle {
    Authenticating = 0,
    LoadingInitialData = 1,
    Ready = 2
}

declare global {
    interface Document {
        userName: string,
        token: string
    }
}
export class WebGame extends React.Component<WebGameProps, WebGameState>
{
    constructor(props: WebGameProps) {
        super(props);
        this.state = {
            authToken: "",
            activePlayer: "test",
            activeNavState: AvailbleNavStates.Home,
            activeLifeCycle: WebGameLifeCycle.Authenticating,
            summaryData: undefined
        };

    }

    handleLogin = (res: any) => {
        const activeEmail = res.profileObj.email;
        let activePlayer = "";
        switch (activeEmail) {
            case "tlibey1@gmail.com":
                activePlayer = Constants.UserIDs.TYLER;
                break;
            case "kkim53@gmail.com":
                activePlayer = Constants.UserIDs.KEN;
                break;
            case "brianjmogen@gmail.com":
            case "brian.j.mogen@gmail.com":
                activePlayer = Constants.UserIDs.BRIAN;
                break;
            case "devapratim.sarma@gmail.com":
            case "devapratimsarma@gmail.com":
            case "scifigenius@gmail.com":
                activePlayer = Constants.UserIDs.PREETAM;
                break;
            case "noble.matthew@gmail.com":
            case "noblematthew@gmail.com":
                activePlayer = Constants.UserIDs.MATT;
                break;
            default:
                throw Error("");
        }

        this.setState({
            authToken: res.tokenId,
            activePlayer: activePlayer,
            activeLifeCycle: WebGameLifeCycle.LoadingInitialData
        });
        this.props.apiService.getWebGameSummaryData(res.tokenId, this.handleInitialDataLoaded)

        document.userName = activePlayer;
        document.token = res.tokenId;

    }

    handleInitialDataLoaded = (response: any) => {
        window.console.log(response["data"])
        this.setState({
            activeLifeCycle: WebGameLifeCycle.Ready,
            summaryData: response["data"]
        });
    }


    handleNavClick = (navState: AvailbleNavStates) => {
        this.setState({ activeNavState: navState });
    }

    handleBackClick = () => {
        this.setState({
            activeNavState: AvailbleNavStates.Home,
            activeLifeCycle: WebGameLifeCycle.LoadingInitialData
        });
        this.props.apiService.getWebGameSummaryData(
            this.state.authToken,
            this.handleInitialDataLoaded
        )
    }


    render() {
        const totalTriviaCoinsAvailable = this.state.summaryData?.[Constants.PayloadHelpers.WEB_GAME_CATEGORY_TRIVIA]?.[Constants.PayloadHelpers.WEB_GAME_TOTAL_COINS_AVAIALBLE];
        const triviaCoinsClaimed = this.state.summaryData?.[Constants.PayloadHelpers.WEB_GAME_CATEGORY_TRIVIA]?.[Constants.PayloadHelpers.WEB_GAME_COINS_CLAIMED];
        const totalCodesCoinsAvailable = this.state.summaryData?.[Constants.PayloadHelpers.WEB_GAME_CATEGORY_CODES]?.[Constants.PayloadHelpers.WEB_GAME_TOTAL_COINS_AVAIALBLE];
        const codesCoinsClaimed = this.state.summaryData?.[Constants.PayloadHelpers.WEB_GAME_CATEGORY_CODES]?.[Constants.PayloadHelpers.WEB_GAME_COINS_CLAIMED];
        const totalAdventureCoinsAvailable = this.state.summaryData?.[Constants.PayloadHelpers.WEB_GAME_CATEGORY_ADVENTURE]?.[Constants.PayloadHelpers.WEB_GAME_TOTAL_COINS_AVAIALBLE];
        const adventureCoinsClaimed = this.state.summaryData?.[Constants.PayloadHelpers.WEB_GAME_CATEGORY_ADVENTURE]?.[Constants.PayloadHelpers.WEB_GAME_COINS_CLAIMED];

        const triviaEnabled = triviaCoinsClaimed !== totalTriviaCoinsAvailable;
        const codesEnabled = codesCoinsClaimed !== totalCodesCoinsAvailable;

        // Always enable adventure in case there are cards not claimed
        // [Stretch] could disable this on all 3 cards claimed, but likely heavier than worth
        const adventureEnabled = true;

        return (
            <>
                <Header />
                {this.state.activeLifeCycle === WebGameLifeCycle.Authenticating && <GoogleLogin
                    clientId="78490331453-rllhrrno34jfmmr0j0m0r66edj33dsim.apps.googleusercontent.com"
                    buttonText="Login"
                    cookiePolicy="single_host_origin"
                    onSuccess={this.handleLogin}
                    prompt="consent"
                />}

                {this.state.activeLifeCycle === WebGameLifeCycle.LoadingInitialData
                    && <LoadingSpinner />}

                {this.state.activeLifeCycle === WebGameLifeCycle.Ready &&
                    <>
                        {this.state.activeNavState === AvailbleNavStates.Home &&
                            <>
                                <h2 style={{
                                }}>
                                    {`welcome ${this.state.activePlayer}`}
                                </h2>
                                <div style={{
                                    display: "flex",
                                    margin: "auto",
                                    flexWrap: "wrap",
                                    alignContent: "space-between",
                                    justifyContent: "space-evenly",
                                    alignItems: "center"
                                }}>
                                    <SubCard
                                        onClick={() => {
                                            if (triviaEnabled) {
                                                this.handleNavClick(AvailbleNavStates.Trivia)
                                            }
                                            else {
                                                alert("all trivia complete");
                                            }
                                        }}
                                        header="Trivia"
                                        logo={TRIVIA_LOGO}
                                        coinsClaimed={triviaCoinsClaimed}
                                        coinsTotal={totalTriviaCoinsAvailable}
                                    />
                                    <SubCard
                                        onClick={() => {
                                            if (codesEnabled) {
                                                this.handleNavClick(AvailbleNavStates.Codes)
                                            }
                                            else {
                                                alert("all codes discovered");
                                            }
                                        }}
                                        header="Codes"
                                        logo={PUZZLE_LOGO}
                                        coinsClaimed={codesCoinsClaimed}
                                        coinsTotal={totalCodesCoinsAvailable}
                                    />
                                    <SubCard
                                        onClick={() => {
                                            if (adventureEnabled) {
                                                this.handleNavClick(AvailbleNavStates.Adventure)
                                            }
                                            else {
                                                alert("all advanture coins claimed");
                                            }
                                        }}
                                        header="Adventure"
                                        logo={ADVENTURE_LOGO}
                                        coinsClaimed={adventureCoinsClaimed}
                                        coinsTotal={totalAdventureCoinsAvailable}
                                    />
                                </div>
                            </>
                        }
                        {this.state.activeNavState === AvailbleNavStates.Trivia &&
                            < TriviaCategorySelection
                                apiService={this.props.apiService}
                                authToken={this.state.authToken}
                                activePlayer={this.state.activePlayer}
                                summaryData={this.state.summaryData}
                                onBackClick={this.handleBackClick} />
                        }

                        {this.state.activeNavState === AvailbleNavStates.Adventure &&
                            < Adventure
                                apiService={this.props.apiService}
                                authToken={this.state.authToken}
                                activePlayer={this.state.activePlayer}
                                onBackClick={this.handleBackClick} />
                        }

                        {this.state.activeNavState === AvailbleNavStates.Codes &&
                            < Codes
                                apiService={this.props.apiService}
                                authToken={this.state.authToken}
                                activePlayer={this.state.activePlayer}
                                onBackClick={this.handleBackClick} />
                        }
                    </>
                }
            </>
        );
    }
}
