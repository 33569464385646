import React from "react";
import { APIService } from "../ApiService";
import { Button } from "@mui/material";
import Unity, { UnityContext } from "react-unity-webgl";
import { LoadingSpinner } from "../SharedComponents/LoadingSpinner";

interface AdventureProps {
    apiService: APIService;
    authToken: string;
    activePlayer: string
    onBackClick: () => void;
}

interface AdventureState {
    unityContext?: UnityContext,
    showingWeb: boolean,
    unityLoaded: boolean
}

export class Adventure extends React.Component<AdventureProps, AdventureState>
{
    constructor(props: AdventureProps) {
        super(props);
        this.state = {
            unityContext: undefined,
            showingWeb: false,
            unityLoaded: false
        };
    }

    componentDidMount() {
        const unityContext = new UnityContext({
            loaderUrl: "client/adventure.loader.js",
            dataUrl: "client/adventure.data.unityweb",
            frameworkUrl: "client/adventure.framework.js.unityweb",
            codeUrl: "client/adventure.wasm.unityweb",
        });

        unityContext.on("loaded", () => {
            this.setState({ unityLoaded: true })
        });

        this.setState({ unityContext: unityContext })
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h2>
                    Adventure
                </h2>
                {this.state.unityContext &&
                    <>
                        {this.state.showingWeb ?
                            <>
                                {!this.state.unityLoaded && <LoadingSpinner />}
                                <Unity
                                    unityContext={this.state.unityContext}
                                    matchWebGLToCanvasSize={true}
                                    style={{ width: window.innerWidth, height: window.innerHeight, position: "absolute", top: 0 }}
                                />
                            </>
                            : <>
                                <div>Please ensure your phone is in landscape mode before launching</div>
                                <Button onClick={() => { this.setState({ showingWeb: true }); this.state.unityContext?.setFullscreen(true) }}>
                                    Launch Game in Web
                                </Button>
                            </>
                        }
                    </>
                }
                {!this.state.showingWeb && <>
                    {/*<Button onClick={() => { window.console.log("TODO - download apk") }}>
                        Download APK
                </Button>*/}
                    <Button onClick={this.props.onBackClick}>Go Back</Button></>
                }
            </div>
        );
    }
}
