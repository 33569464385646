
import "./spinner.css"
import React from "react";

export class LoadingSpinner extends React.Component {
    render() {

        return (
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        );
    }
}
