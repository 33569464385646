import * as Constants from "./Constants";
const axios = require('axios').default;


export class APIService {

    url = "https://b0q92w546a.execute-api.us-west-2.amazonaws.com/Prod/everythingisgame";

    getAllData(successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.GET_DATA;
        this.postRequest(body, successCallback, (err: any) => { console.log(err) });
    }

    addActivity(activityName: string, displayName: string, location: string, awardsNotes: string, authtoken: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.CREATE_NEW_ACTIVITY;
        body[Constants.PayloadHelpers.TOKEN] = authtoken

        let payload: any = {};
        payload[Constants.ActivityRepositoryFieldNames.ACTIVITY_NAME] = activityName;
        payload[Constants.ActivityRepositoryFieldNames.DISPLAY_NAME] = displayName;
        payload[Constants.ActivityRepositoryFieldNames.LOCATION] = location;
        payload[Constants.ActivityRepositoryFieldNames.AWARDS_NOTES] = awardsNotes;

        body[Constants.PayloadHelpers.NEW_ACTIVITY_PAYLOAD] = payload;
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) })
    }

    deleteActivity(activityName: string, authtoken: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.DELETE_ACTIVITY;
        body[Constants.PayloadHelpers.TOKEN] = authtoken

        let payload: any = {};
        payload[Constants.ActivityRepositoryFieldNames.ACTIVITY_NAME] = activityName;

        body[Constants.PayloadHelpers.DELETE_ACTIVITY_PAYLOAD] = payload;
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) })
    }

    completeActivity(activityName: string, pointsGranted: any, coinsGranted: any, authtoken: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.COMPLETE_ACTIVITY;
        body[Constants.PayloadHelpers.TOKEN] = authtoken

        let payload: any = {};
        payload[Constants.ActivityRepositoryFieldNames.ACTIVITY_NAME] = activityName;
        payload[Constants.ActivityRepositoryFieldNames.POINTSGRANTED] = pointsGranted;
        payload[Constants.ActivityRepositoryFieldNames.COINSGRANTED] = coinsGranted;

        body[Constants.PayloadHelpers.COMPLETED_ACTIVITY_PAYLOAD] = payload;
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) })
    }


    clearActivity(activityName: string, authtoken: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.CLEAR_ACTIVITY;
        body[Constants.PayloadHelpers.TOKEN] = authtoken

        let payload: any = {};
        payload[Constants.ActivityRepositoryFieldNames.ACTIVITY_NAME] = activityName;

        body[Constants.PayloadHelpers.CLEAR_ACTIVITY_PAYLOAD] = payload;
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) })
    }

    addAdjustment(userId: string, pointsAdjusted: number, coinsAdjusted: number, notes: string, authtoken: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.ADD_ADJUSTMENT;
        body[Constants.PayloadHelpers.TOKEN] = authtoken

        let payload: any = {};
        payload[Constants.AdjustmentRepositoryFieldNames.USERID] = userId;
        payload[Constants.AdjustmentRepositoryFieldNames.POINT_ADJUSTMENT] = pointsAdjusted.toString();
        payload[Constants.AdjustmentRepositoryFieldNames.COIN_ADJUSTMENT] = coinsAdjusted.toString();
        payload[Constants.AdjustmentRepositoryFieldNames.NOTES] = notes;

        body[Constants.PayloadHelpers.ADJUSTMENT_PAYLOAD] = payload;
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) })
    }

    deleteAdjustment(userId: string, timeStamp: string, authtoken: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.DELETE_ADJUSTMENT;
        body[Constants.PayloadHelpers.TOKEN] = authtoken

        let payload: any = {};
        payload[Constants.AdjustmentRepositoryFieldNames.USERID] = userId;
        payload[Constants.AdjustmentRepositoryFieldNames.TIMESTAMP] = timeStamp;

        body[Constants.PayloadHelpers.DELETE_ADJUSTMENT_PAYLOAD] = payload;
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) })
    }


    addPurchase(userId: string, coinsSpent: number, notes: string, specialCard: string, authtoken: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.ADD_PURCHASE;
        body[Constants.PayloadHelpers.TOKEN] = authtoken

        let payload: any = {};
        payload[Constants.PurchaseRepositoryFieldNames.USERID] = userId;
        payload[Constants.PurchaseRepositoryFieldNames.COINS_SPENT] = coinsSpent.toString();
        payload[Constants.PurchaseRepositoryFieldNames.PURCHASE_NOTES] = notes;
        payload[Constants.PayloadHelpers.ADVENTURE_SPECIAL_CARD_PURCHASE_PAYLOAD_FIELD_NAME] = specialCard;

        body[Constants.PayloadHelpers.ADD_PURCHASE_PAYLOAD] = payload;
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) })
    }

    deletePurchase(userId: string, timeStamp: string, authtoken: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.DELETE_PURCHASE;
        body[Constants.PayloadHelpers.TOKEN] = authtoken

        let payload: any = {};
        payload[Constants.PurchaseRepositoryFieldNames.USERID] = userId;
        payload[Constants.PurchaseRepositoryFieldNames.TIMESTAMP] = timeStamp;

        body[Constants.PayloadHelpers.DELETE_PURCHASE_PAYLOAD] = payload;
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) })
    }

    getWebGameSummaryData(authtoken: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.GET_WEBGAME_SUMMARY;
        body[Constants.PayloadHelpers.TOKEN] = authtoken
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) });
    }

    getTriviaQuestion(authtoken: string, category: string, questionNumber: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.GET_TRIVIA_QUESTION;
        body[Constants.PayloadHelpers.TOKEN] = authtoken
        let payload: any = {};
        payload[Constants.WebGameFieldNames.TRIVIA_QUESTION_CATAGORY] = category;
        payload[Constants.WebGameFieldNames.TRIVIA_QUESTION_NUMBER] = questionNumber;

        body[Constants.PayloadHelpers.WEB_GAME_GET_QUESTION_PAYLOAD] = payload;
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) });
    }

    submitTriviaAnswer(authtoken: string, category: string, questionNumber: string, answer: string, userId: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.SUBMIT_TRIVIA_ANSWER;
        body[Constants.PayloadHelpers.TOKEN] = authtoken
        let payload: any = {};
        payload[Constants.WebGameFieldNames.TRIVIA_QUESTION_CATAGORY] = category;
        payload[Constants.WebGameFieldNames.TRIVIA_QUESTION_NUMBER] = questionNumber;
        payload[Constants.WebGameFieldNames.TRIVIA_QUESTION_ANSWER] = answer;
        payload[Constants.AdjustmentRepositoryFieldNames.USERID] = userId;

        body[Constants.PayloadHelpers.WEB_GAME_CHECK_ANSWER_PAYLOAD] = payload;
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) });
    }

    submitCodeAnswer(authtoken: string, answer: string, userId: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.SUBMIT_CODE;
        body[Constants.PayloadHelpers.TOKEN] = authtoken
        let payload: any = {};
        payload[Constants.WebGameFieldNames.CODE_ANSWER] = answer;
        payload[Constants.AdjustmentRepositoryFieldNames.USERID] = userId;

        body[Constants.PayloadHelpers.WEB_GAME_CHECK_CODE_PAYLOAD] = payload;
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) });
    }

    getCodeHint(authtoken: string, successCallback: any) {
        let body: any = {};
        body[Constants.Events.EVENT_TYPE] = Constants.Events.GET_CODE_HINT;
        body[Constants.PayloadHelpers.TOKEN] = authtoken
        console.log(body);
        this.postRequest(body, successCallback, (err: any) => { console.log(err) });
    }


    postRequest(payload: any, successCallback: any, failureCallback: any) {
        const headers: any = {
            Accept: 'text/plain',
            'Content-Type': 'text/plain'
        };

        const request: any = {
            method: 'POST',
            headers: headers,
            data: payload,
            url: this.url
        };

        axios(request)
            .then(successCallback)
            .catch(failureCallback);
    }
}