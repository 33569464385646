import React from "react";
import * as Constants from "./Constants";
import { Button, Card, Icon } from "@mui/material";

interface AdjustmentListItemProps {
    adjustment: any;
    onDeleteCallback: (userID: string, timeStamp: string) => void;
}

interface AdjustmentListItemState {
    showingDetails: boolean
}

export class AdjustmentListItem extends React.Component<AdjustmentListItemProps, AdjustmentListItemState>
{
    constructor(props: AdjustmentListItemProps) {
        super(props);
        this.state = { showingDetails: false };
    }

    render() {
        return (
            <Card style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: "400px",
                margin: "auto",
                marginBottom: "4px"
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-evenly"
                }} onClick={() => { this.setState((prevState) => { return { showingDetails: !prevState.showingDetails }; }) }}>
                    <div>{this.props.adjustment[Constants.AdjustmentRepositoryFieldNames.USERID]} </div>
                    <div>{"Point: " + this.props.adjustment[Constants.AdjustmentRepositoryFieldNames.POINT_ADJUSTMENT]}</div>
                    <div>{"Coin: " + this.props.adjustment[Constants.AdjustmentRepositoryFieldNames.COIN_ADJUSTMENT]}</div>
                    <Icon >{this.state.showingDetails ? "arrow_drop_down" : "arrow_right"}</Icon>

                </div>
                {this.state.showingDetails &&
                    <>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                            <div>{this.props.adjustment[Constants.AdjustmentRepositoryFieldNames.TIMESTAMP]}</div>
                            <div>{this.props.adjustment[Constants.AdjustmentRepositoryFieldNames.NOTES]}</div>
                        </div>
                        <Button style={{
                            paddingBottom: "18px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                            onClick={() => {
                                this.props.onDeleteCallback(
                                    this.props.adjustment[Constants.PurchaseRepositoryFieldNames.USERID],
                                    this.props.adjustment[Constants.PurchaseRepositoryFieldNames.TIMESTAMP]
                                )
                            }}>
                            <div>Remove Adjustment </div>
                            <Icon>delete</Icon>
                        </Button>
                    </>
                }
            </Card>
        );
    }
}
