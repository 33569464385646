import React from "react";
import { ScoreCard } from "./ScoreCard";
import { Header } from "./Header";
import BRIAN from "./Assets/brian_33icon.png";
import KEN from "./Assets/ken.png";
import MATT from "./Assets/matt_logo.jpg";
import PREETAM from "./Assets/preetam.png";
import TYLER from "./Assets/mango_logo_2.png";
import { APIService } from "./ApiService";
import * as Constants from "./Constants";
import { LoadingSpinner } from "./SharedComponents/LoadingSpinner";

interface LeaderboardProps {
  apiService: APIService;
  showLiveData: boolean;
}

interface LeaderboardState {
  playerData: AllPlayers;
  dataLoaded: boolean;
}

export type PlayerData = {
  displayName: string
  currentScore?: string
  currentCurrencyBalance?: string
  imgSource: string
  borderColor: string
  imagebgColor?: string,
  ledger?: string[]
}

type AllPlayers = {
  brian: PlayerData,
  ken: PlayerData,
  matt: PlayerData,
  preetam: PlayerData,
  tyler: PlayerData
}


export class Leaderboard extends React.Component<LeaderboardProps, LeaderboardState>
{
  constructor(props: LeaderboardProps) {
    super(props);
    this.state = {
      playerData: this.setInitialPlayerData(),
      dataLoaded: false,
    };
  }

  setInitialPlayerData(): AllPlayers {
    return {
      brian: {
        displayName: "windjammer",
        imgSource: BRIAN,
        borderColor: "#90B6BA",
        imagebgColor: "#f5f3e6"
      },
      ken: {
        displayName: "KirbyKX",
        imgSource: KEN,
        borderColor: "#325992"
      },
      matt: {
        displayName: "KönigderKatzen",
        imgSource: MATT,
        borderColor: "#3F704D",
        imagebgColor: "#86dbf7"
      },
      preetam: {
        displayName: "ds_postal",
        imgSource: PREETAM,
        borderColor: "#6D071A",
      },
      tyler: {
        displayName: "CupOfMango",
        imgSource: TYLER,
        borderColor: "#871900",
      }
    }
  }

  componentDidMount() {
    if (this.props.showLiveData) {
      this.props.apiService.getAllData(this.onDataReceived);
    }
  }

  onDataReceived = (response: any) => {
    const data = response["data"];
    const byPerson = data[Constants.PayloadHelpers.DATA_BY_PERSON];
    const mattScore = byPerson[Constants.UserIDs.MATT][Constants.PayloadHelpers.TOTAL_POINTS];
    const mattCoins = byPerson[Constants.UserIDs.MATT][Constants.PayloadHelpers.TOTAL_COINS];
    const mattLedger = byPerson[Constants.UserIDs.MATT][Constants.PayloadHelpers.BY_PERSON_LEDGER];
    const brianScore = byPerson[Constants.UserIDs.BRIAN][Constants.PayloadHelpers.TOTAL_POINTS];
    const brianCoins = byPerson[Constants.UserIDs.BRIAN][Constants.PayloadHelpers.TOTAL_COINS];
    const brianLedger = byPerson[Constants.UserIDs.BRIAN][Constants.PayloadHelpers.BY_PERSON_LEDGER];
    const kenScore = byPerson[Constants.UserIDs.KEN][Constants.PayloadHelpers.TOTAL_POINTS];
    const kenCoins = byPerson[Constants.UserIDs.KEN][Constants.PayloadHelpers.TOTAL_COINS];
    const kenLedger = byPerson[Constants.UserIDs.KEN][Constants.PayloadHelpers.BY_PERSON_LEDGER];
    const preetamScore = byPerson[Constants.UserIDs.PREETAM][Constants.PayloadHelpers.TOTAL_POINTS];
    const preetamCoins = byPerson[Constants.UserIDs.PREETAM][Constants.PayloadHelpers.TOTAL_COINS];
    const preetamLedger = byPerson[Constants.UserIDs.PREETAM][Constants.PayloadHelpers.BY_PERSON_LEDGER];
    const tylerScore = byPerson[Constants.UserIDs.TYLER][Constants.PayloadHelpers.TOTAL_POINTS];
    const tylerCoins = byPerson[Constants.UserIDs.TYLER][Constants.PayloadHelpers.TOTAL_COINS];
    const tylerLedger = byPerson[Constants.UserIDs.TYLER][Constants.PayloadHelpers.BY_PERSON_LEDGER];


    this.setState((prevState) => {
      return {
        dataLoaded: true,
        playerData: {
          brian: { ...prevState.playerData.brian, currentCurrencyBalance: brianCoins, currentScore: brianScore, ledger: brianLedger },
          ken: { ...prevState.playerData.ken, currentCurrencyBalance: kenCoins, currentScore: kenScore, ledger: kenLedger },
          matt: { ...prevState.playerData.matt, currentCurrencyBalance: mattCoins, currentScore: mattScore, ledger: mattLedger },
          preetam: { ...prevState.playerData.preetam, currentCurrencyBalance: preetamCoins, currentScore: preetamScore, ledger: preetamLedger },
          tyler: { ...prevState.playerData.tyler, currentCurrencyBalance: tylerCoins, currentScore: tylerScore, ledger: tylerLedger }
        }
      }
    });
  }

  render() {

    let playerData = [
      this.state.playerData.brian,
      this.state.playerData.ken,
      this.state.playerData.matt,
      this.state.playerData.preetam,
      this.state.playerData.tyler
    ];
    playerData.sort((a: PlayerData, b: PlayerData) => {
      if (a.currentScore && b.currentScore) {
        return a.currentScore < b.currentScore ? 1 : -1
      }
      return 1
    })

    return (
      <>
        <Header />

        <div style={{
          display: "flex",
          margin: "auto",
          flexWrap: "wrap",
          alignContent: "space-between",
          justifyContent: "space-evenly",
          alignItems: "center"
        }}>
          {
            (this.state.dataLoaded || !this.props.showLiveData) ? <>
              {playerData.map((playerData) => {
                return <ScoreCard
                  key={playerData.displayName}
                  playerData={playerData}
                />
              })}</> : <LoadingSpinner />
          }
        </div>
      </>
    );
  }
}
