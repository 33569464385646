import { Button, TextField } from "@mui/material";
import React from "react";

interface AddNewActivityFormProps {
  onActivitySubmitted: (activityName: string, activityDisplayName: string, activityLocation: string, activityAwardsNotes: string) => void;
}

interface AddNewActivityFormState {
  activityName: string,
  activityDisplayName: string,
  activityAwardsNotes: string,
  activityLocation: string
}


export class AddNewActivityForm extends React.Component<AddNewActivityFormProps, AddNewActivityFormState>
{
  constructor(props: AddNewActivityFormProps) {
    super(props);
    this.state = {
      activityAwardsNotes: "",
      activityDisplayName: "",
      activityLocation: "",
      activityName: ""
    };
  }

  onActivitySubmitted = () => {
    this.props.onActivitySubmitted(this.state.activityName, this.state.activityDisplayName, this.state.activityLocation, this.state.activityAwardsNotes);
    this.setState({
      activityName: "",
      activityDisplayName: "",
      activityLocation: "",
      activityAwardsNotes: ""
    });
  }


  render() {

    const allowSubmission: boolean = !!this.state.activityName
      && !!this.state.activityDisplayName
      && !!this.state.activityLocation
      && !!this.state.activityAwardsNotes;

    return (
      <>
        <h3> Add new activity</h3>
        <div style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center"
        }}>
          <TextField
            onChange={(event) => { this.setState({ activityName: event.target.value }) }}
            label="Activity Name/ID"
            value={this.state.activityName}
            style={{ margin: "10px" }}
          />
          <TextField
            onChange={(event) => { this.setState({ activityDisplayName: event.target.value }) }}
            label="Display Name"
            value={this.state.activityDisplayName}
            style={{ margin: "10px" }}
          />
          <TextField
            onChange={(event) => { this.setState({ activityLocation: event.target.value }) }}
            label="Location"
            value={this.state.activityLocation}
            style={{ margin: "10px" }}
          />
          <TextField
            onChange={(event) => { this.setState({ activityAwardsNotes: event.target.value }) }}
            label="Awards Notes"
            value={this.state.activityAwardsNotes}
            style={{ margin: "10px" }}
          />
          <Button
            onClick={this.onActivitySubmitted}
            disabled={!allowSubmission}
            style={{ margin: "10px" }}
          >
            Submit
          </Button>
        </div>
      </>
    );
  }
}
