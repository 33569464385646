import { Button, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import * as Constants from "./Constants";

interface AddNewAdjustmentFormProps {
  onAdjustmentSubmitted: (userId: string, pointsAdjusted: number, coinsAdjusted: number, notes: string) => void;
}

interface AddNewAdjustmentFormState {
  userID: string,
  CoinAdjustment: number,
  PointsAdjustment: number,
  Notes: string
}


export class AddNewAdjustmentForm extends React.Component<AddNewAdjustmentFormProps, AddNewAdjustmentFormState>
{
  constructor(props: AddNewAdjustmentFormProps) {
    super(props);
    this.state = {
      userID: "",
      CoinAdjustment: 0,
      PointsAdjustment: 0,
      Notes: "",
    };
  }

  onAdjustmentSubmitted = () => {
    this.props.onAdjustmentSubmitted(
      this.state.userID,
      this.state.PointsAdjustment,
      this.state.CoinAdjustment,
      this.state.Notes);

    this.setState({
      userID: "",
      PointsAdjustment: 0,
      CoinAdjustment: 0,
      Notes: ""
    });
  }


  render() {

    const allowSubmission: boolean = !!this.state.Notes
      && !!this.state.userID
      && (this.state.CoinAdjustment !== 0 || this.state.PointsAdjustment !== 0);

    return (
      <>
        <h3> Add new Adjustment</h3>
        <div style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center"
        }}>
          <Select
            label="UserName"
            onChange={(event) => { this.setState({ userID: event.target.value as string }) }}
            value={this.state.userID}
            sx={{
              width: "120px",
              margin: "10px"
            }}
          >
            <MenuItem value={Constants.UserIDs.BRIAN}>{Constants.UserIDs.BRIAN}</MenuItem>
            <MenuItem value={Constants.UserIDs.MATT}>{Constants.UserIDs.MATT}</MenuItem>
            <MenuItem value={Constants.UserIDs.KEN}>{Constants.UserIDs.KEN}</MenuItem>
            <MenuItem value={Constants.UserIDs.PREETAM}>{Constants.UserIDs.PREETAM}</MenuItem>
            <MenuItem value={Constants.UserIDs.TYLER}>{Constants.UserIDs.TYLER}</MenuItem>
          </Select>
          <TextField
            onChange={(event) => { this.setState({ PointsAdjustment: Number.parseInt(event.target.value) }) }}
            type="number"
            label="Point Adjustment"
            style={{ margin: "10px" }}
            value={this.state.PointsAdjustment}
          />
          <TextField
            onChange={(event) => { this.setState({ CoinAdjustment: Number.parseInt(event.target.value) }) }}
            type="number"
            label="Coin Adjustment"
            style={{ margin: "10px" }}
            value={this.state.CoinAdjustment}
          />
          <TextField
            onChange={(event) => { this.setState({ Notes: event.target.value }) }}
            label="Notes"
            style={{ margin: "10px" }}
            value={this.state.Notes}
          />
          <Button
            onClick={this.onAdjustmentSubmitted}
            disabled={!allowSubmission}
            style={{ margin: "10px" }}
          >
            Submit
          </Button>
        </div>
      </>
    );
  }
}
