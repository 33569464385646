export class Events {
    static EVENT_TYPE: string = "eventType"
    static COMPLETE_ACTIVITY = "complete-activity"
    static CREATE_NEW_ACTIVITY = "create-activity"
    static DELETE_ACTIVITY = "delete-activity"
    static CLEAR_ACTIVITY = "clear-activity"
    static ADD_PURCHASE = "add-purchase"
    static DELETE_PURCHASE = "delete-purchase"
    static ADD_ADJUSTMENT = "add-adjustment"
    static DELETE_ADJUSTMENT = "delete-adjustment"
    static GET_DATA = "get-all-data"

    static GET_WEBGAME_SUMMARY = "get-webgame-summary"
    static GET_TRIVIA_QUESTION = "get-trivia-question"
    static SUBMIT_TRIVIA_ANSWER = "submit-trivia-answer"
    static GET_CODE_HINT = "get-code-hint"
    static SUBMIT_CODE = "submit-code"

    static CLAIM_ADVENTURE_PRIZE = "claim-prize"
    static CLAIM_ADVENTURE_ITEM = "claim-item"
    static GET_ADVENTURE_SUMMARY = "get-adventure-summary"
}


export class UserIDs {
    static TYLER = "tyler"
    static BRIAN = "brian"
    static PREETAM = "preetam"
    static MATT = "matt"
    static KEN = "ken"
}

export class PayloadHelpers {
    static NEW_ACTIVITY_PAYLOAD = "new-activity-payload"
    static COMPLETED_ACTIVITY_PAYLOAD = "complete-activity-payload"
    static DELETE_ACTIVITY_PAYLOAD = "delete-activity-payload"
    static CLEAR_ACTIVITY_PAYLOAD = "clear-activity-payload"
    static ADD_PURCHASE_PAYLOAD = "add-purchase-payload"
    static DELETE_PURCHASE_PAYLOAD = "delete-purchase-payload"
    static ADJUSTMENT_PAYLOAD = "add-adjustment"
    static DELETE_ADJUSTMENT_PAYLOAD = "delete-adjustment"
    static ACTIVITY_LIST = "all-activities"
    static PURCHASE_LIST = "all-purchases"
    static ADJUSTMENT_LIST = "all-adjustments"
    static DATA_BY_PERSON = "by-person"
    static TOTAL_POINTS: string = "total-points"
    static TOTAL_COINS = "total-coins"
    static BY_PERSON_LEDGER = "by-person-ledger"
    static TOKEN = "authToken"

    static WEB_GAME_GET_QUESTION_PAYLOAD = "get-trivia-question"
    static WEB_GAME_CHECK_ANSWER_PAYLOAD = "check-trivia-answer"
    static TRIVIA_QUESTION_ANSWER_WAS_CORRECT = "triviaQuestionAnswerWasCorrect"
    static WEB_GAME_CATEGORY_TRIVIA = "category-trivia"
    static WEB_GAME_TRIVIA_TYLER_CATEGORY = "tyler"
    static WEB_GAME_TRIVIA_FOOD_AND_BEER_CATEGORY = "foodandbeer"
    static WEB_GAME_TRIVIA_GAMES_CATEGORY = "games"

    static WEB_GAME_CHECK_CODE_PAYLOAD = "check-code"
    static WEB_GAME_GET_CODE_HINT_PAYLOAD = "get-hint"
    static WEB_GAME_CODE_ALREADY_SOLVED = "alread-solved"
    static WEB_GAME_CODE_INCORRECT = "not-found"
    static WEB_GAME_CODE_SUCCESS = "code-solved"
    static WEB_GAME_CODE_RESULT = "code-result"
    static WEB_GAME_CATEGORY_CODES = "category-codes"

    static WEB_GAME_CATEGORY_ADVENTURE = "category-adventure"

    static WEB_GAME_TOTAL_COINS_AVAIALBLE = "coins-available"
    static WEB_GAME_COINS_CLAIMED = "coins-claimed"

    static CLAIM_ADVENTURE_PRIZE_PAYLOAD = "claim-prize-payload"
    static CLAIM_ADVENTURE_ITEM_PAYLOAD = "claim-item-payload"
    static ADVENTURE_SPECIAL_CARD_PURCHASE_PAYLOAD_FIELD_NAME = "special-purchase"
}

export class ActivityRepositoryFieldNames {
    static ACTIVITY_NAME = "activityName"
    static DISPLAY_NAME = "displayName"
    static LOCATION = "location"
    static AWARDS_NOTES = "awardNotes"
    static COMPLETED = "completed" //(NOTE - can use this to display all 'non-completed' activities to select from to score)
    static POINTSGRANTED = "pointsGranted" //(Map of userID: points)
    static COINSGRANTED = "coinsGranted" //(Map of userID: coins)
    static TIMESTAMP = "timeStamp"
}

export class PurchaseRepositoryFieldNames {
    static USERID = "userID" // PK
    static TIMESTAMP = "timeStamp" //SK
    static PURCHASE_NOTES = "purchaseNotes" // freeform display notes
    static COINS_SPENT = "coinsSpent" // to subtract from user total
}

export class AdjustmentRepositoryFieldNames {
    static USERID = "userID" // PK
    static TIMESTAMP = "timeStamp" // SK
    static COIN_ADJUSTMENT = "coinAdjustment" //freeform display notes
    static POINT_ADJUSTMENT = "pointAdjustment" // to subtract from user total
    static NOTES = "notes" // what the adjustment is for
}

export class WebGameFieldNames {
    static TRIVIA_QUESTION_CATAGORY = "questionCatagory" // PK
    static TRIVIA_QUESTION_NUMBER = "questionNumber" // SK
    static TRIVIA_QUESTION_QUESTION_TEXT = "questionText"
    static TRIVIA_QUESTION_ANSWER = "answer"
    static TRIVIA_QUESTION_COINS_TO_AWARD = "coinsToAward"
    static TRIVIA_QUESTION_COINS_CLAIMED = "coinsClaimed"


    static CODE_ANSWER = "codeAnswer"
    static CODE_HINT = "codeHint"
    static CODE_COINS_TO_AWARD = "coinsToAward"
    static CODE_COINS_CLAIMED = "coinsClaimed"
    static CODE_COINS_CLAIMED_BY = "coinsClaimedBy"
    static CODE_COINS_CLAIMED_TIMESTAMP = "coinsClaimedTimeStamp"

}

export class AdventureConstants {
    static KEY_ITEM = "key"
    static PELT_ITEM = "pelt"
    static SHOES_ITEM = "shoes"
    static ABOVE_ITEM = "Cloud Strike"
    static BLADE_ITEM = "Buster Sword"
    static CLOAK_ITEM = "Wolf Cloak"
    static CLAIM_SUCCESS = "claim-success"
    static CLAIM_FAILURE = "claim-failure"
    static CLAIM_RESULT = "code-result"
    static SUMMARY_AVAILABLE_PRIZES = "available-prizes"
    static SUMMARY_EQUIPPED_ITEMS = "equipped-items"
    static SUMMARY_KEY_ITEMS_ALREADY_CLAIMED = "key-items-already-claimed"
}